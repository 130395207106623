export default () => {
	const svgs = [...document.querySelectorAll('.js-theme-title-svg')];

	svgs.forEach(svg => {
		const bbox = svg.getBBox();
		// const text = svg.querySelector('.js-theme-title-text');
		//
		// const res = text.innerHTML.replace(/\D/g, '');
		// if (res !== '') {
		// 	svg.classList.add('has-numbers');
		// 	if (res >= 10) svg.classList.add('has-decimal-numbers');
		//
		// 	text.innerHTML = text.innerHTML
		// 		.replace(res, `<tspan lengthAdjust='spacing' class='numbers'>${res}</tspan>`);
		// }

		svg.setAttribute('viewBox', `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`);
		svg.classList.add('is-ready');
	});
};
