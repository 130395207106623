import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default () => {
	let tl = gsap.timeline();

	const splash = document.querySelector('.hero__figure--overlay');
	const splashStatic = document.querySelector('.hero__figure--static');
	const text = document.querySelector('.hero__text');
	const scrollBlock = document.querySelector('.hero__scroll');
	const cig = document.querySelector('.hero__cig');
	const bg = document.querySelector('.hero__bg');
	const frame = document.querySelector('.hero__border');
	const terrain = document.querySelector('.hero__terrain');

	if (!splash) {
		gsap.set(document.body, {
			'overflow': 'auto'
		});
		return;
	}
	
	gsap.set(splash, {
		opacity: 0,
	});
	gsap.set(splash, {
		width: splashStatic.offsetWidth,
		height: splashStatic.offsetHeight
	});
	

	let offset = splash.getBoundingClientRect().top - splashStatic.getBoundingClientRect().top;

	gsap.set(splash, {
		width: '100%',
		height: '100%'
	});


	tl.fromTo(splash, {
		opacity: 0.5
	}, {
		opacity: 1,
		width: splashStatic.offsetWidth,
		height: splashStatic.offsetHeight,
		duration: 3,
		ease: "back.out(1.7)"
	})

	tl.to(splash, {
		'margin-top': -offset,
		duration: 3,
		ease: "back.out(1.7)",
		onComplete: ()=>{
			gsap.set(splashStatic, {
				opacity: 1,
				'pointer-events': 'all'
			});
			splash.remove();
		}
	}, 0);

	tl.fromTo([text], {
		opacity: 0,
		y: 200
	}, {
		opacity: 1,
		y: 0,
		duration: 2
	}, 0);

	tl.fromTo([scrollBlock], {
		y: 200
	}, {
		y: 0,
		duration: 2
	}, 0);

	tl.to(cig, {
		opacity: 1,
		duration: 2
	}, 0);

	tl.to(frame, {
		opacity: 1,
		duration: 2
	}, 0);

	tl.to(bg, {
		yPercent: -24,
		duration: 3,
		ease: "back.out(1.7)"
	}, 0);

	tl.to(terrain, {
		opacity: 1,
		duration: 2.5,
		y: 0,
		ease: "back.out(1.7)",
		onComplete: ()=>{
			gsap.set(document.body, {
				'overflow': 'auto'
			});
		}
	}, 0.5);
}