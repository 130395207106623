import setFullHeight from './helpers/setFullHeight';
import hero from './components/hero';
import titles from './components/titles';


export default () => {
	setFullHeight(); //Set VH variable for mobile safari 100VH, use scss mixin fullheight()
	hero();
	titles();
};
